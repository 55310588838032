import React from "react";
import "./bankCard.scss";
import {Fade} from "react-reveal";
export default function BankCard({bank_details,isDark}) {

  return (
    <Fade bottom duration={1000} distance="20px">
      <div>
        <div
          className={isDark ? "dark-card-mode bank-card-div" : "bank-card-div"}
        >
          <div className="bank-name-div">
            {/* <svg
              aria-hidden="true"
              className="octicon repo-svg"
              height="20"
              role="img"
              viewBox="0 0 12 16"
              width="14"
            >
                <path fill="#66757F" d="M3 16h30v18H3z"/><path fill="#CCD6DD" d="M2 34h32a2 2 0 0 1 2 2H0a2 2 0 0 1 2-2z"/>
                <path fill="#292F33" d="M18 23a3 3 0 0 0-3 3v6h6v-6a3 3 0 0 0-3-3z"/>
                <path fill="#CCD6DD" d="M3 21h4v11H3zm6 0h4v11H9zm20 0h4v11h-4zm-6 0h4v11h-4z"/>
                <path fill="#AAB8C2" d="M2 32h32v2H2z"/><path fill="#66757F" d="M36 11L18 0L0 11z"/>
                <path fill="#CCD6DD" d="M18 2.4L2 12v4h32v-4z"/><path fill="#8899A6" d="M3 19h4v2H3zm6 0h4v2H9zm14 0h4v2h-4zm6 0h4v2h-4z"/>
                <path fill="#CCD6DD" d="M1 12h34v5H1z"/><path fill="#AAB8C2" d="M36 12a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1v-1a1 1 0 0 1 1-1h34a1 1 0 0 1 1 1v1zm0 6a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1v-1a1 1 0 0 1 1-1h34a1 1 0 0 1 1 1v1z"/>
                <path fill="#E1E8ED" d="M13 32h10v2H13z"/><path fill="#F5F8FA" d="M11 34h14v2H11z"/>
              <path
                fill-rule="evenodd"
                d="M4 9H3V8h1v1zm0-3H3v1h1V6zm0-2H3v1h1V4zm0-2H3v1h1V2zm8-1v12c0 .55-.45 1-1 1H6v2l-1.5-1.5L3 16v-2H1c-.55 0-1-.45-1-1V1c0-.55.45-1 1-1h10c.55 0 1 .45 1 1zm-1 10H1v2h2v-1h3v1h5v-2zm0-10H2v9h9V1z"
              ></path>
            </svg> */}
            <svg aria-hidden="true" role="img" width="3rem" height="2rem" preserveAspectRatio="xMidYMid meet" viewBox="0 0 36 36"   className="octicon repo-svg">
                <path fill="#66757F" d="M3 16h30v18H3z"/><path fill="#CCD6DD" d="M2 34h32a2 2 0 0 1 2 2H0a2 2 0 0 1 2-2z"/>
                <path fill="#292F33" d="M18 23a3 3 0 0 0-3 3v6h6v-6a3 3 0 0 0-3-3z"/>
                <path fill="#CCD6DD" d="M3 21h4v11H3zm6 0h4v11H9zm20 0h4v11h-4zm-6 0h4v11h-4z"/>
                <path fill="#AAB8C2" d="M2 32h32v2H2z"/><path fill="#66757F" d="M36 11L18 0L0 11z"/>
                <path fill="#CCD6DD" d="M18 2.4L2 12v4h32v-4z"/><path fill="#8899A6" d="M3 19h4v2H3zm6 0h4v2H9zm14 0h4v2h-4zm6 0h4v2h-4z"/>
                <path fill="#CCD6DD" d="M1 12h34v5H1z"/><path fill="#AAB8C2" d="M36 12a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1v-1a1 1 0 0 1 1-1h34a1 1 0 0 1 1 1v1zm0 6a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1v-1a1 1 0 0 1 1-1h34a1 1 0 0 1 1 1v1z"/>
                <path fill="#E1E8ED" d="M13 32h10v2H13z"/><path fill="#F5F8FA" d="M11 34h14v2H11z"/></svg>
            <p className="repo-name">{bank_details.bank_name}</p>
          </div>
          <p className="repo-description">{bank_details.account_holder_name}</p>
          <div className="repo-stats">
            <div className="repo-left-stat">
            <span className="account_number">
                  <p >A/C : {bank_details.account_number}</p>
                </span>
              <span className="ifsc_code">
                <p>IFSC CODE : {bank_details.ifsc_code}</p>
              </span>
           
            </div>
          
          </div>
     
        </div>
      </div>
    </Fade>
  );
}

import React, {useContext} from "react";
import {Fade} from "react-reveal";
import StyleContext from "../../contexts/StyleContext";
import Carousal from "../../components/carousal/carousal";
import "./gallery.scss";
import {gallerySection} from "../../portfolio";

import 'react-gallery-carousel/dist/index.css';
export default function Gallery() {
  const {isDark} = useContext(StyleContext);
  return (
    <Fade bottom duration={1000} distance="20px">
      <div className="main gallery-margin-top" id="gallery">
        <div className={isDark ? "dark-mode gallery-div-main" : "gallery-div-main"}>
        {gallerySection.galleries.map((gallery, i) => {
                  return (
                    <Carousal
                      key={i}
                      isDark={isDark}
                      images={gallery.images}
                      title ={gallery.title}
                    />
                  );
                })}
    
        </div>
      </div>
    </Fade>
  );
}



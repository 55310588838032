import React from "react";
import {Fade} from "react-reveal";
import "./carousal.scss";
import Carousel from 'react-gallery-carousel';
import 'react-gallery-carousel/dist/index.css';
export default function Carousal({images,title, isDark}) {
  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
  }
  const styles = {
    containerHeight: {
        height: JSON.stringify(getWindowDimensions().height)-120+'px',
    }
  };
  return (
    <Fade bottom duration={1000} distance="20px">
      <div className="main gallery-margin-top" id="gallery">
        <div className={isDark ? "dark-mode gallery-div-main" : "gallery-div-main"}>
        <div className="contact-header">
            <h1 className="heading carousal-title">{title}</h1>
            </div>
            <div className='carousel-container'  style={styles.containerHeight}>
        <Carousel images={images} />
      </div>
        </div>
      </div>
    </Fade>
  );
}



import React, {useContext,useState} from "react";
import Headroom from "react-headroom";
import "./Header.scss";
import ToggleSwitch from "../ToggleSwitch/ToggleSwitch";
import StyleContext from "../../contexts/StyleContext";
import {
  greeting,
  workExperiences,
  aboutSection,
  gallerySection,
  blogSection,
  talkSection,
  achievementSection,
  donateSection,
  contactInfo
} from "../../portfolio";


function Header() {
  const {isDark} = useContext(StyleContext);
  const viewExperience = workExperiences.display;
  const viewGallery = gallerySection.display;
  const viewAbout = aboutSection.display;
  const viewAchievement = achievementSection.display;
  const viewBlog = blogSection.display;
  const viewTalks = talkSection.display;
  const viewDonate = donateSection.display;
  const viewContact = contactInfo.display;
  const [ActiveLink, setActiveLink] = useState('');
  function clickedAnchor(activeLink) {
    setActiveLink(activeLink)
  }
  return (
    <Headroom>
      <header className={isDark ? "dark-menu header" : "header"}>

        <a href="/" className="logo">
      <span title="H3" className="logo-image"></span>

          {/* <span className="grey-color"> &lt;</span> */}

          <span title="Home" className="logo-name">{greeting.username}</span>
          {/* <span className="grey-color">/&gt;</span> */}
        </a>
        <input className="menu-btn" type="checkbox" id="menu-btn" />
        <label
          className="menu-icon"
          htmlFor="menu-btn"
          style={{color: "white"}}
        >
          <span className={isDark ? "navicon navicon-dark" : "navicon"}></span>
        </label>
        <ul className={isDark ? "dark-menu menu" : "menu"}>
          {viewAbout && (
            <li onClick={() => clickedAnchor("about")}>
              <a href="#about" className={(ActiveLink  === "about" ? 'active' : '')}>About </a>
            </li>
          )}
          {viewExperience && (
            <li onClick={() => clickedAnchor("experience")}>
              <a href="#experience" className={(ActiveLink  === "experience" ? 'active' : '')}>Work Experiences</a>
            </li>
          )}
          {viewGallery && (
            <li onClick={() => clickedAnchor("gallery")}>
              <a href="#gallery" className={(ActiveLink  === "gallery" ? 'active' : '')}>Gallery</a>
            </li>
          )}
          {viewAchievement && (
            <li onClick={() => clickedAnchor("achievements")}>
              <a href="#achievements" className={(ActiveLink  === "achievements" ? 'active' : '')}>Achievements</a>
            </li>
          )}
          {viewBlog && (
            <li onClick={() => clickedAnchor("blogs")}>
              <a href="#blogs" className={(ActiveLink  === "blogs" ? 'active' : '')}>Blogs</a>
            </li>
          )}
          {viewTalks && (
            <li onClick={() => clickedAnchor("talks")}>
              <a href="#talks" className={(ActiveLink  === "talks" ? 'active' : '')}>Talks</a>
            </li>
          )}
          {viewDonate && (
            <li onClick={() => clickedAnchor("donate")}>
            <a href="#donate" className={(ActiveLink  === "donate" ? 'active' : '')}>Donate</a>
          </li>
          )}
            {viewContact && (
               <li onClick={() => clickedAnchor("contact")}>
               <a href="#contact" className={(ActiveLink  === "contact" ? 'active' : '')}>Contact Us</a>
             </li>
            )}
         
        
          <li>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a>
              <ToggleSwitch />
            </a>
          </li>
        </ul>
      </header>
    </Headroom>
  );
}
export default Header;

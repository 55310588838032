import React, {useContext} from "react";
import "./Donate.scss";
import {illustration, donateSection} from "../../portfolio";
import {Fade} from "react-reveal";
import charityBox from "../../assets/lottie/charityBox.json";
import DisplayLottie from "../../components/displayLottie/DisplayLottie";
import StyleContext from "../../contexts/StyleContext";
import BankCard from "../../components/bankCard/bankCard";
import UPICard from "../../components/upiCard/upiCard";

export default function Donate() {
  const {isDark} = useContext(StyleContext);
  if (!donateSection.display) { 
    return null;
  }
  return (
    <div className={isDark ? "dark-mode main donate-top-margin" : "main donate-top-margin"} id="donate">
      <div className="donate-main-div">
        <Fade left duration={1000}  distance="20px">
          <div className="donate-image-div">
            {illustration.animated ? (
              <DisplayLottie animationData={charityBox} />
            ) : (
              <img
                alt="Man Working"
                src={require("../../assets/images/donate-button.png")}
              ></img>
            )}
          </div>
        </Fade>
        <Fade right duration={1000}>
          <div className="donate-text-div">
            <h1
              className={isDark ? "dark-mode donate-heading" : "donate-heading"}
            >
              {donateSection.title}{" "}
            </h1>
            <p
              className={
                isDark
                  ? "dark-mode subTitle donate-text-subtitle"
                  : "subTitle donate-text-subtitle"
              }
            >
              {donateSection.subTitle}
            </p>
        
            <BankCard bank_details={donateSection.bank_details} isDark={isDark}/>
            <br/>
            <UPICard upi_details={donateSection.upi_details} isDark={isDark} />
            {/* <SoftwareSkill /> */}
            {/* <div>
              {donateSection.donate.map((donate, i) => {
                return (
                  <p
                    key={i}
                    className={
                      isDark
                        ? "dark-mode subTitle donate-text"
                        : "subTitle donate-text"
                    }
                  >
                    {donate}
                  </p>
                );
              })}
            </div> */}
          </div>
        </Fade>
      </div>
    </div>
  );
}
